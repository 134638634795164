.card{

    margin-top: 100px;
}

.resumePage{
    align-self: center;
}

.download{
    text-align: center;
}