#ProfilePic {
  border-radius: 200px;
  float: left;
 
  margin-top: auto;
  margin-right: 20px;
  position: relative;
} 


.container {
  margin-bottom: 200px;
}

.card {
  box-shadow: 5px 10px 8px 10px #888888;
  margin-right: 200px;
  background-color: rgb(243, 243, 243);

}

/* .paragraph {
  padding-left: 20px;
  margin-right: 15px;
} */

.footer {
  text-align: center;
}

.paragraph{
  margin-top:100px;
  margin-right: auto;
}
