.wrapper {
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: flex-start;
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
 }
  