.row {
  margin: auto;
  height: auto;
  background-color: rgb(243, 243, 243);
}

.card{
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

.card-img {
  margin: auto;
}
