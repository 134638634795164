.nav {
  align-content: left;
    margin-bottom: 5px;
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
}

/* .logo{
    margin-right: 100px;
    width: 100px;
    height: 100px;
}
*/

.nav-item {
  margin-bottom: 0px;
  justify-self: center;
}
